import fetchJson from 'lib/utils/fetchJson';
import { mutate } from 'swr';
import { getCheckoutIdByLocale } from 'lib/shopify';
import { getShopifyGid } from './shopifyGid';

const manageFreeItem = async (cartData, freeItem, freeItemResources, locale) => {
  if (!freeItem) return;

  const checkoutId = getCheckoutIdByLocale(locale);

  const { amountOfProductsToGetFreeItem, promoCollectionName, shopifyTagsToExcludeFromPromo } =
    freeItemResources;

  const lineItemsCopy = [...cartData?.data?.lineItems?.edges];

  // amountOfProductsToGetFreeItem.text is coming from globalmodules.secondpartsettings
  // Adjustable amount of items you would get the free item, 1, 2, or 3. If left empty then the default will be 2
  const MIN_AMOUNT_OF_PRODUCTS = parseInt(amountOfProductsToGetFreeItem.text, 10) || 2;

  // shopifyTagsToExcludeFromPromo.text is coming from globalmodules.secondpartsettings
  // source: text. A string value that equals to an existing product tag in Shopify. Multiple tags are accepted separated by comma.
  const EXCLUDED_TAGS_ARRAY = shopifyTagsToExcludeFromPromo.text?.replace(/\s/g, '')?.split(',');

  // promoCollectionName.text is coming from globalmodules.secondpartsettings
  // source: text. A string value that is checked if it is a part of the Shopify product handle. Multiple values are accepted separated by comma.
  const PLP_NAMES_ARRAY = promoCollectionName.text?.replace(/\s/g, '')?.split(',');

  const getFilteredLineItemsQuantity = (cartContents, freeItemVariantId) => {
    const getIsProductExcludedBy = productTags => {
      if (EXCLUDED_TAGS_ARRAY?.length) {
        return !productTags?.some(p => EXCLUDED_TAGS_ARRAY.includes(p));
      }
      return true;
    };
    const getIsProductInPromoBy = productHandle =>
      PLP_NAMES_ARRAY?.some(plpName => productHandle?.includes(plpName));

    // filter our free item by variant id and travel size products by handle
    const filteredCart = cartContents.filter(
      ({ node: { variant } }) =>
        variant?.id !== freeItemVariantId &&
        getIsProductInPromoBy(variant?.product?.handle) &&
        getIsProductExcludedBy(variant?.product?.tags)
    );

    if (filteredCart.length > 1) {
      return filteredCart.reduce((acc, currentLineItem) => acc + currentLineItem.node?.quantity, 0);
    }

    if (filteredCart.length === 0) return 0;
    return filteredCart[0].node?.quantity;
  };

  const freeItemEncodedVariantId = getShopifyGid('ProductVariant', freeItem?.variants[0]?.id);
  const totalItemQuantity = getFilteredLineItemsQuantity(lineItemsCopy, freeItemEncodedVariantId);

  const freeItemInCart = lineItemsCopy.find(
    lineItem => lineItem.node.variant?.id === freeItemEncodedVariantId
  );

  if (totalItemQuantity < MIN_AMOUNT_OF_PRODUCTS && !freeItemInCart) return;

  if (totalItemQuantity < MIN_AMOUNT_OF_PRODUCTS && freeItemInCart) {
    const lineItemId = freeItemInCart.node.id;
    const updatedCart = await fetchJson('/api/shopify/checkout/remove-items/', {
      method: 'POST',
      body: JSON.stringify({
        checkoutId,
        lineItemIds: [lineItemId],
        locale,
      }),
    });
    await mutate('/api/shopify/checkout/request/', { ...updatedCart, locale }, false);
  } else if (totalItemQuantity >= MIN_AMOUNT_OF_PRODUCTS && !freeItemInCart) {
    const updatedCartData = await fetchJson('/api/shopify/checkout/add-items/', {
      method: 'POST',
      body: JSON.stringify({
        checkoutId,
        lineItems: [{ variantId: freeItemEncodedVariantId, quantity: 1 }],
        locale,
      }),
    });

    await mutate('/api/shopify/checkout/request/', { ...updatedCartData, locale }, false);
  }
};

export default manageFreeItem;
