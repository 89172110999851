import fetchJson from 'lib/utils/fetchJson';
import manageFreeItem from 'lib/shopify/manageFreeItem';
import { useSettings } from 'contexts/SettingContext';
import { mutate } from 'swr';
import { useRouter } from 'next/router';
import { getCheckoutIdByLocale } from 'lib/shopify';
import { useCart } from './useCart';

export function useUpdateItemQuantity() {
  const { cart } = useCart();
  const { isFreeItemDisabled } = useSettings();
  const { locale } = useRouter();

  async function updateItemQuantity(items, freeItem, freeItemResources) {
    // {variantId: lineItem.variant.id, quantity: quantity}

    const checkoutId = getCheckoutIdByLocale(locale);

    if (!items || !items.length) return;

    const itemErrors = items.reduce((prev, item) => {
      const err = [...prev];

      if (item.variantId == null) {
        err.push(`Missing variantId in item`);
      }
      if (item.quantity == null) {
        err.push(`Missing quantity in item with variant id: ${item.variantId}`);
      }
      if (typeof item.quantity !== 'number') {
        err.push(`Quantity is not a number in item with variant id: ${item.variantId}`);
      }
      if (item.quantity < 0) {
        err.push(`Quantity must not be negative in item with variant id: ${item.variantId}`);
      }

      return err;
    }, []);

    if (itemErrors.length) {
      console.log(itemErrors);
      // eslint-disable-next-line consistent-return
      return null;
    }

    async function replaceLineItem(newItems) {
      // combine duplicates
      const currLineItems = cart.lineItems.edges.reduce((acc, { node }) => {
        const { variant, customAttributes, quantity } = node;
        const index = acc.findIndex(item => item.variantId === variant?.id);
        if (index === -1) {
          return [...acc, { variantId: variant?.id, customAttributes, quantity }];
        }
        acc[index].quantity += quantity;
        return acc;
      }, []);

      // update item quantity
      const newLineItems = currLineItems.reduce(
        (prev, { variantId, customAttributes, quantity }) => {
          const newQuantity = newItems.find(item => item.variantId === variantId)?.quantity;
          if (newQuantity === 0) {
            return prev;
          }
          if (!newQuantity) {
            return [...prev, { variantId, customAttributes, quantity }];
          }
          return [...prev, { variantId, customAttributes, quantity: newQuantity }];
        },
        []
      );

      const data = await fetchJson('/api/shopify/checkout/replace-items/', {
        method: 'POST',
        body: JSON.stringify({
          checkoutId,
          lineItems: newLineItems,
          locale,
        }),
      });
      // Update cart

      await mutate('/api/shopify/checkout/request/', { ...data, locale }, false);

      // If freeitem adding has been disabled
      // we don't need to do anything
      if (!isFreeItemDisabled) {
        await manageFreeItem(data, freeItem, freeItemResources, locale);
      }
      return data;
    }
    // eslint-disable-next-line consistent-return
    return replaceLineItem(items);
  }

  return updateItemQuantity;
}

export default useUpdateItemQuantity;
