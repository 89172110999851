import useCart from 'hooks/shop/useCart';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import useUpdateItemQuantity from 'hooks/shop/useUpdateItemQuantity';

const parseDiscountsConditions = discounts => {
  const allDiscounts = (!Array.isArray(discounts) ? [discounts] : discounts).filter(
    d => d?.data?.status === 'ACTIVE'
  );

  return allDiscounts?.map(discount => {
    const discountTitle = discount?.data?.title;
    const discountId = discount?.discount;
    const condition = discount?.data?.customerBuys?.items;
    const value = discount?.data?.customerBuys?.value;
    const freeProducts = discount?.data?.customerGets?.items?.products?.edges.map(({ node }) => {
      const { id, title, featuredImage, handle } = node || {};
      const variantId = node?.variants?.edges?.[0]?.node?.id;
      return { id, variantId, title, featuredImage, handle };
    });

    if (condition?.type === 'DiscountProducts') {
      const products = condition?.products?.edges?.map(({ node }) => node?.id);
      return { discountTitle, discountId, products, value, freeProducts };
    }

    if (condition?.type === 'DiscountCollections') {
      const collections = condition?.collections?.edges?.map(({ node }) => node?.id);
      return { discountTitle, discountId, collections, value, freeProducts };
    }

    if (condition?.type === 'AllDiscountItems') {
      return { discountTitle, discountId, allItems: true, value, freeProducts };
    }

    return null;
  });
};

const nrOfCondicionItemsInCart = (condition, lineItems) =>
  lineItems?.reduce((acc, { node }) => {
    if (!condition) return acc;
    const lineItemVariantId = node?.variant?.id;
    const lineItemQuantity = node?.quantity;
    if (condition.freeProducts.some(({ variantId }) => variantId === lineItemVariantId)) {
      acc += lineItemQuantity;
    }
    return acc;
  }, 0);

const getFreeProductsByCondition = (conditions, lineItems) => {
  if (!conditions.length || !lineItems) {
    return {
      allFreeItems: null,
      unusedFreeItems: null,
    };
  }
  const allFreeItems = [];
  const unusedFreeItems = [];

  conditions?.forEach(condition => {
    const neededQuantity = condition?.value?.quantity; // the quantity of items that should be in the cart to get the free item
    const neededAmount = condition?.value?.amount; // the amount of money that should be in the cart to get the free item
    // calculating the amount of line items and the total price of line items that satisfy the condition
    const lineItemsPassed = lineItems?.reduce(
      (acc, { node }) => {
        if (!condition) return acc;
        const { quantity } = node;
        const productId = node?.variant?.product.id;
        const lineItemCollections = node?.variant?.product?.collections?.edges?.map(
          ({ node: c }) => c?.id
        );
        const { products, collections, allItems } = condition;

        // if the condition is for all items, we take all items in account
        if (allItems) {
          acc.amount += quantity;
          acc.totPrice += quantity * node?.variant?.priceV2?.amount;
        }

        // if the condition is for specific products, check if lineItem is one of them
        if (products?.includes(productId)) {
          acc.amount += quantity;
          acc.totPrice += quantity * node?.variant?.priceV2?.amount;
        }

        // if the condition is for specific collections, check if lineItem is a part of condition collections
        if (collections?.some(c => lineItemCollections?.includes(c))) {
          acc.amount += quantity;
          acc.totPrice += quantity * node?.variant?.priceV2?.amount;
        }

        return acc;
      },
      { amount: 0, totPrice: 0 }
    );

    // if the amount of line items or the total price of line items is enough to satisfy the condition, add the free item from the condition to result
    if (
      (neededQuantity && neededQuantity <= lineItemsPassed?.amount) ||
      (neededAmount && neededAmount <= lineItemsPassed?.totPrice)
    ) {
      const timesSatisfied = Math.floor(
        // how many times the condition is satisfied
        neededQuantity
          ? lineItemsPassed?.amount / neededQuantity
          : lineItemsPassed?.totPrice / neededAmount
      );

      const conditionItemsInCart = nrOfCondicionItemsInCart(condition, lineItems);

      allFreeItems.push({ ...condition });
      if (conditionItemsInCart < timesSatisfied) {
        unusedFreeItems.push({
          ...condition,
          itemsLeft: timesSatisfied - conditionItemsInCart,
        });
      }
    }
  });

  return {
    allFreeItems,
    unusedFreeItems,
  };
};

const deletInvalidProductsFromCart = (validFreeItems, lineItems, updateItemQuantity) => {
  if (!lineItems) return;

  const freeLineItems = lineItems?.filter(({ node }) =>
    node?.customAttributes.some(({ key }) => key === '_freeItem')
  );
  if (!freeLineItems?.length) return;

  const isFreeItemValid = (validItems, item) => {
    if (!validItems) return false;

    const variantId = item?.node?.variant?.id;
    return validFreeItems.some(({ freeProducts }) =>
      freeProducts.some(({ variantId: freeVariantId }) => variantId === freeVariantId)
    );
  };

  const freeItemsToDelete = freeLineItems?.filter(item => !isFreeItemValid(validFreeItems, item));
  if (!freeItemsToDelete?.length) return;

  const functionInput = freeItemsToDelete?.map(({ node }) => ({
    variantId: node?.variant?.id,
    quantity: 0,
  }));

  updateItemQuantity(functionInput);
};

/*
 * Currently is not used. Keeping it for future references, because the promotions are almost the same.
 * shopifyDiscountBxgyId = globalModules?.layout?.shopifyDiscountBxgyId
 * */
export function useDiscountFreeItem(idsArr, locale) {
  const { cart, isEmpty } = useCart();
  const [freeProducts, setFreeProducts] = useState([]);
  const [validFreeProducts, setValidFreeProducts] = useState(null);
  const updateItemQuantity = useUpdateItemQuantity();

  const fetcher = (...urls) => {
    const f = u => fetch(u).then(r => r.json());
    if (urls.length > 1) {
      return Promise.all(urls.map(f));
    }
    return f(urls);
  };

  const urls = idsArr.map(
    id => `/api/shopify/get-automatic-discount-by-id/?discountId=${id}&locale=${locale}`
  ); // fetching all available discounts by id
  const { data: discounts } = useSWR(urls, fetcher);

  useEffect(() => {
    const conditions = parseDiscountsConditions(discounts);
    const availableFreeItems = getFreeProductsByCondition(conditions, cart?.lineItems?.edges);
    setFreeProducts(availableFreeItems.unusedFreeItems);
    setValidFreeProducts(availableFreeItems.allFreeItems);
  }, [cart?.lineItems?.edges, isEmpty, discounts]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      deletInvalidProductsFromCart(validFreeProducts, cart?.lineItems?.edges, updateItemQuantity);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [cart?.lineItems?.edges, validFreeProducts]);

  return {
    discounts,
    freeProducts,
  };
}

export default useDiscountFreeItem;
